export default {
  methods: {
    CustomTooltip (options) {
      return function tooltip (chart) {
        let sumData = 0
        const $chart = chart.container
        const $toolTip = document.createElement('div')
        $toolTip.className = 'ct-tooltip'
        $chart.appendChild($toolTip)
        document.body.appendChild($toolTip)

        let height = $toolTip.offsetHeight
        let width = $toolTip.offsetWidth

        hide($toolTip)

        if (options.percentage === true) {
          chart.data.series.forEach(element => {
            sumData += element.value
          })
        }

        function on (event, selector, callback) {
          $chart.addEventListener(event, function (e) {
            callback(e)
          })
        }

        on('mousemove', null, function (event) {
          let value = event.target.getAttribute('ct:value')
          if (value === null) value = event.target.getAttribute('value')
          if (value !== null) {
            value = getValue(value)
            $toolTip.innerHTML = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            setPosition(event)
            show($toolTip)
          } else if (value !== null) {
            setPosition(event)
          } else {
            hide($toolTip)
          }
        })

        function setPosition (event) {
          height = height || $toolTip.offsetHeight
          width = width || $toolTip.offsetWidth
          const offsetX = -width / 2 + options.tooltipOffset.x
          const offsetY = -height + options.tooltipOffset.y
          $toolTip.style.top = event.pageY + offsetY + 'px'
          $toolTip.style.left = event.pageX + offsetX + 'px'
        }
        function getValue (value) {
          if (options.percentage === true) {
            return (Math.round(10 * 100 * value / sumData) / 10).toString() + ' %'
          } else {
            return value
          }
        }
      }
      function show (element) {
        if (!hasClass(element, 'tooltip-show')) {
          element.className = element.className + ' tooltip-show'
        }
      }
      function hide (element) {
        const regex = new RegExp('tooltip-show' + '\\s*', 'gi')
        element.className = element.className.replace(regex, '').trim()
      }
      function hasClass (element, className) {
        return (' ' + element.getAttribute('class') + ' ').indexOf(' ' + className + ' ') > -1
      }
    }
  }
}
