<!-- DEPRECATED -->
<template>
    <v-card outlined :height="height + 100">
      <v-card-title class="justify-center">
        <slot name="title"/>
      </v-card-title>
      <v-divider />
      <v-card-text >
        <chartist
          ratio="ct-chart ct-perfect-fourth"
          type="Pie"
          :data="chartData"
          :options="chartOptions">
        </chartist>
      </v-card-text>
    </v-card>
</template>

<script>
import ChartistSliceDonutMargin from 'chartist-plugin-slicedonutmargin'
import chartistTooltipMixin from '@/utils/mixins/charts/chartistTooltip.mixin'

export default {
  name: 'pibot-chartist-donut',
  mixins: [chartistTooltipMixin],
  props: {
    data: {
      type: Object,
      default: () => ({ 'Very Low': 8, Low: 2, Medium: 2, High: 2, 'Very High': 1 }) // DUMMY DATA,
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    showValues: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 250
    },
    useTooltip: {
      type: Boolean,
      default: true
    },
    colorScheme: {
      type: Array,
      default: function () { return ['#000'] }
    },
    donutWidth: {
      type: Number,
      default: 15
    },
    chartPadding: {
      type: Number,
      default: 25
    },
    sliceMargin: {
      type: Number,
      default: 6
    },
    labelOffset: {
      type: Number,
      default: 15
    }
  },
  data: () => ({}),
  computed: {
    chartData () {
      const series = Object.values(this.data)
      const labels = Object.keys(this.data)
      const newSeries = []
      const newLabels = []
      for (let i = 0; i < series.length; i++) {
        if (series[i] !== 0) {
          newSeries.push({ value: series[i], className: labels[i] })
          newLabels.push(labels[i])
        }
      }
      return {
        labels: newLabels,
        series: newSeries
      }
    },
    chartOptions () {
      const plugins = [
        this.CustomTooltip({
          percentage: true,
          tooltipOffset: {
            x: 0,
            y: -30
          }
        })
      ]
      if (this.useTooltip) {
        plugins.push(
          ChartistSliceDonutMargin({
            sliceMargin: this.sliceMargin
          })
        )
      }
      return {
        donut: true,
        showLabel: this.showLabels,
        chartPadding: this.chartPadding,
        donutWidth: this.donutWidth,
        labelOffset: this.labelOffset,
        labelDirection: 'explode',
        height: this.height,
        plugins: plugins
      }
    }
  }
}
</script>

<style>
.ct-label {
  pointer-events: none;
  font: 1em 'Roboto', sans-serif;
  fill: #5c5c5c
}

.Very.Low, .Seldom, .Incidental {
  stroke: lightgreen;
  stroke-linecap: round
}
.Low, .Rare, .Minor {
  stroke: green;
  stroke-linecap: round
}
.Medium, .Remote, .Moderate {
  stroke: orange;
  stroke-linecap: round
}
.High, .Unlikely, .Major  {
  stroke: orangered;
  stroke-linecap: round
}
.Severe, .Occasional {
  stroke: red;
  stroke-linecap: round
}
.Very.High, .Likely, .Catastrophic {
  stroke: darkred;
  stroke-linecap: round
}

.ct-chart {
  position: relative;
}

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 3em;
  padding: 5px 5px;
  background: #383838;
  border-radius: 5%;
  text-anchor: middle;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  opacity: 0;
  font-family: 'Roboto, sans-serif';
  font-size: '20';
  transition: opacity .2s linear;
}

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}
.ct-tooltip.tooltip-show {
  opacity: 0.8;
}

</style>
